@font-face {
  font-family: "db_heaventbold";
  src: url("./assets/fonts/db_heavent_bd_v3.2-webfont.eot");
  src: url("./assets/fonts/db_heavent_bd_v3.2-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/db_heavent_bd_v3.2-webfont.woff2") format("woff2"),
    url("./assets/fonts/db_heavent_bd_v3.2-webfont.woff") format("woff"),
    url("./assets/fonts/db_heavent_bd_v3.2-webfont.ttf") format("truetype"),
    url("./assets/fonts/db_heavent_bd_v3.2-webfont.svg#db_heaventbold")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "db_heaventmed";
  src: url("./assets/fonts/db_heavent_med_v3.2-webfont.eot");
  src: url("./assets/fonts/db_heavent_med_v3.2-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/db_heavent_med_v3.2-webfont.woff2") format("woff2"),
    url("./assets/fonts/db_heavent_med_v3.2-webfont.woff") format("woff"),
    url("./assets/fonts/db_heavent_med_v3.2-webfont.ttf") format("truetype"),
    url("./assets/fonts/db_heavent_med_v3.2-webfont.svg#db_heaventmed")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "db_heaventthin";
  src: url("./assets/fonts/db_heavent_thin_v3.2-webfont.eot");
  src: url("./assets/fonts/db_heavent_thin_v3.2-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/db_heavent_thin_v3.2-webfont.woff2") format("woff2"),
    url("./assets/fonts/db_heavent_thin_v3.2-webfont.woff") format("woff"),
    url("./assets/fonts/db_heavent_thin_v3.2-webfont.ttf") format("truetype"),
    url("./assets/fonts/db_heavent_thin_v3.2-webfont.svg#db_heaventthin")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "db_heavent";
  src: url("./assets/fonts/db_heavent_v3.2-webfont.eot");
  src: url("./assets/fonts/db_heavent_v3.2-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/db_heavent_v3.2-webfont.woff2") format("woff2"),
    url("./assets/fonts/db_heavent_v3.2-webfont.woff") format("woff"),
    url("./assets/fonts/db_heavent_v3.2-webfont.ttf") format("truetype"),
    url("./assets/fonts/db_heavent_v3.2-webfont.svg#db_heaventthin")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

$fill-bg-content: #f1f5f6;
$fill-blue: #007bfe;
$fill-blue-header: #4b75c0;
$fill-blue-table: #037afb;
$fill-dark-grey: #252526;
$fill-light-grey: #dddddd;
$fill-grey-font: #939ca9;
$fill-red: #d80012;
$fill-white: #fff;
$fill-black: #000;

$shadow: 3px 3px 10px rgba(0, 0, 0, 0.08);

* {
  box-sizing: border-box;
  // margin: 0;
  // padding: 0;
  outline: none;
}

p, div, button, a, h1, h2, h3, h4, h5, h6, span, input {
  margin: 0;
  padding: 0;
}

hr {
  border: 1px solid $fill-light-grey;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: normal;
  font-family: "db_heaventmed";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 0.6;
}

ul {
  // list-style: none;
}

input[type="text"],
input[type="password"] {
  border-radius: 4px;
  padding: 12px 10px;
  // font-family: "db_heaventmed";
  font-size: 1rem;
  border: none;
}

img {
  max-width: 100%;
  max-height: 100%;
}

button {
  border: none;
  margin: 0 10px;
  background-color: transparent;
  font-size: 1.4rem;

  &:focus {
    outline: none !important;
    outline-offset: none !important;
  }
}

// Core ----------------------------------------------------------//
body {
  background-color: #f1f5f6;
  // color: $fill-grey-font;
  font-size: 16px;
  font-family: "db_heaventmed", sans-serif;
}

.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 3;
  display: flex;
  color: #fff;
  font-size: 1.4rem;
  align-items: center;
  justify-content: center;
}

.App-error,
.App-loading {
  width: 100%;
  height: 100vh;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
  p {
    font-size: 1.4rem;
  }
}

#root {
  .btn-back {
    position: absolute;
    top: 20px;
    left: 20px;
  }
}

.loading {
  &.container {
    .container-top {
      height: 100vh;

      h3 {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 100px;
        height: 80px;
        z-index: 99;
      }
    }
  }
}

.mg-t-5 {
  margin-top: 5px !important;
}

.mg-t-10 {
  margin-top: 10px !important;
}

.mg-t-15 {
  margin-top: 15px !important;
}

.mg-t-20 {
  margin-top: 20px !important;
}

.mg-t-30 {
  margin-top: 30px !important;
}

.mg-t-40 {
  margin-top: 40px !important;
}

.mg-b-5 {
  margin-top: 5px !important;
}

.mg-b-10 {
  margin-top: 10px !important;
}

.mg-b-15 {
  margin-top: 15px !important;
}

.mg-b-20 {
  margin-top: 20px !important;
}

.mg-b-30 {
  margin-top: 30px !important;
}

.mg-b-40 {
  margin-top: 40px !important;
}

.font-white {
  color: $fill-white;
}

.font-red {
  color: $fill-red;
}

.font-dark-grey {
  color: $fill-dark-grey;
}

.font-blue {
  color: $fill-blue;
}

// Modal ----------------------------------------------------------//
._modal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto 0;
  // background-color: $fill-white;
  width: auto;
  border-radius: 10px;
  // transform: translate(0, 15%);

  &.modal-error {
    width: 80%;

    ._info-modal {
      border-radius: 10px;
    }
  }

  ._info-modal{
    background-size: 100% 100%;
    background-repeat: no-repeat;
    text-align: center;
    padding: 20% 40px 14%;
    position: relative;

    h1 {
      line-height: 1;
      margin-top: 7%;
      margin-bottom: 10px;
      font-size: 2.2rem;
      font-family: "db_heaventbold";
    }

    ._body {
      overflow: auto;
      padding: 0 12%;
      text-align: left;
      margin-bottom: 10%;

      &.itemdetail {
        text-align: center;
        .itemdetail-header {
          // margin-top: 0;
          font-family: "db_heaventbold";
        }
        .itemdetail-subheader {
          margin-top: 6px;
          font-family: "db_heaventmed";
          font-size: 1.6rem;
          // line-height: 1;
        }
        p {
          font-family: "db_heavent";
          font-size: 1.4rem;
          // line-height: .8; 
        }
        .itemdetail-img {
          width: 100%;
          height: 50%;
          display: flex;
          flex-flow: column wrap;
          justify-content: center;
          position: relative;
          img {
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
          }
        }
      }

      &.termcondition {
        // font-size: 1.6rem;
        p, span, div, a, li {
          font-size: 1.6rem;
          // font-size: inherit;
        }
      }

      p {
        font-size: 1.2rem;
      }

      .award {
        color: $fill-blue;
      }

      .line {
        color: $fill-light-grey;
      }

      .name {
        font-family: "db_heaventbold";
        font-size: 1.4rem;
      }
    }

    .modal-item {
      border-bottom: 1px solid $fill-light-grey;
      padding: 10px 0;
    }

    p {
      color: $fill-black;
      font-size: 1.6rem;
      &.font-red {
        font-size: 1.2rem;
        color: $fill-red;
        text-align: left;
      }
    }

    button {
      margin: 6px auto 0;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -16px;
      max-width: 315px;
      width: 100%;
      height: auto;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      font-family: "db_heaventmed", sans-serif;
      padding: 14px;
      p {
        color: $fill-white;
      }
      &.btn-round {
        // min-width: 180px;
        background-color: $fill-white;
        box-shadow: $shadow;
        width:  180px;
        height: auto;
        padding: 18px 16%;
        margin: auto;
        border-radius: 4px;
        font-size: 1.2rem;
        position: static;
      
        &._blue {
          background-color: #007bfe;
          color: #fff;
        }
      
        &._grey {
          background-color: #878787;
          color: #000;
        }
      }
    }
  }
}

// Button -----------------------------//
.btn-close {
  position: absolute;
  right: 18%;
  z-index: 5;
  margin-top: 8px;
  // top: 0;
  img {
    width: 20px;
  }
}

.btn-gotoplay {
  margin: 0 0 6px;
  max-width: 315px;
  width: 100%;
  height: auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  font-family: "db_heaventmed", sans-serif;
  padding: 14px;
}

.App-link {
  font-family: "db_heaventmed", sans-serif;
}


// Home Page --------------------------//
.home-page {
  width: 100%;
  height: 100vh;
  background-size: 100%;
  background-repeat: no-repeat;
  text-align: center;
  .logo {
    max-width: 512px;
    width: 100%;
  }
}


// Playgame Page --------------------------//
.playgame-page {
  width: 100%;
  height: 100vh;
  background-size: 100%;
  background-repeat: no-repeat;
  text-align: center;
  position: relative;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
 
  .list {
    display: flex;
    padding: 0 2% 5%;

    .overlay {
      width: 96%;
      height: 96%;
      background-color: $fill-black;
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      opacity: 0.25;
    }

    .item {
      width: 100%;
      background-size: auto 100%;
      background-repeat: no-repeat;
      background-position: center;
      padding: 20px;
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      position: relative;
      img {
        width: 76%;
        margin: auto;
      }
      .number {
        position: absolute;
        right: 2px;
        bottom: 4px;
        background-color: #fff;
        padding: 4px;
        border-radius: 100px;
        border: 3px solid $fill-blue-header;
        width: 40px;
        height: 40px;
        line-height: 1;
        font-size: 1.6rem;
      }
    }

    .list-section {
      width: 35%;
      margin: 0;
      &.center {
        width: 30%;
        margin: 0;
        .overlay {
          width: 90%;
          height: 96%;
        }
        .number {
          right: 10px;
        }
      }
    }
  }
}



.img-congratulation {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  max-height: 60%;
}


// Responsive ----------------------//
@media (max-width: 320px) {
  body {
    font-size: 8px;
  }
}
